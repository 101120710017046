import * as React from "react";
import HeaderAndMeta from "../../components/HeaderAndMeta";
import Privacy from "../../components/Privacy";
import Footer from "../../components/Footer";
import { graphql } from "gatsby";

const IndexPage = () => {
  return (
    <>
      <main>
        <HeaderAndMeta />
        <div>
          <Privacy />
        </div>
        <Footer />
      </main>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
