import * as React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <section id="home" className="w-full relative z-20 overflow-hidden">
        <div className="absolute right-0 top-0 w-200 h-200 lg:w-320 lg:h-320">
          <img className="w-full h-full object-contain" width="320" height="320" src="/images/icons/top-rarr.svg" alt="right-arrow" />
        </div>

        <div className="pt-100 md:pt-120 lg:pt-144 container">
          <div className="flex items-start flex-col lg:flex-row relative overflow-visible">
            <div className="text-navy-blue lg:shrink-0 lg:w-640">
              <h1 className="font-heading font-bold text-28 md:text-48">Privacy Policy</h1>
              <div className="mt-20 mb-30 md:my-40 font-normal leading-relaxed">
                Thank you for visiting dcSpark’s Flint Wallet application (the “App”).  This Privacy Policy describes the personal data handling policies of dcSpark solely in connection with the App and the Google store page from which the App is installed, including the following:
                <ol className="mt-20 ml-30 list-decimal">
                    <li>What personal data we collect in connection with the App;</li>
                    <li>How we use the data; </li>
                    <li>With whom we may share the personal data;</li>
                    <li>Legal basis for processing the data;</li>
                    <li>Storage and security of your data;</li>
                    <li>Your Privacy Rights, Choices and Disclosures, including those for Europe and California;</li>
                    <li>Users outside the United States;</li>
                    <li>Links to other sites;</li>
                    <li>Privacy Policy updates; and</li>
                    <li>Contact Information.
                    </li>
                </ol>
                <h2 className="font-bold text-28 mt-20 mb-30">1.	Personal Data We Collect or Process</h2>
                <p className="mb-20">We process data to provide the core functionality of the App and to collect analytical data to help us improve the App.</p>
                <p className="mb-20">Some information is processed automatically in connection with the installation of the App on the Google store page utilizing background local storage and session storage technologies (“Cookies”).  Cookies are small files or other pieces of data which are downloaded or stored on your computer or other device, that can be tied to information about your use of the Google store page. Examples of information of this type are your IP address and the operating system you are using.  The Cookies may be “session” Cookies that last until you close your browser or “persistent” cookies that last until you or your browser delete them. You may change your browser setting to decline the use of Cookies.</p>
                <p className="mb-20">On the Google store page, we use Google Analytics, a web analytics service of Google which uses Cookies. For more information regarding how Google collects, uses, and shares your information please visit <a href="http://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>. The information regarding your use of the Google store page that is generated by the Cookie is transferred to a Google server in the United States and stored there. Google will use this information to analyze your use of the page, compile reports on activity for us (such as number of installations of the App). Google may also transmit this information to third parties where required by law or to the extent that third parties process these data on behalf of Google. You can deactivate Google Analytics using a browser add-on if you do not wish the analysis to take place. You can download the add-on here:  <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</p>

                <p className="mb-20">The App utilizes temporary logs for analytics, debugging and error reporting purposes which involves the processing of your IP address.  In connection with user queries to our server to check the state of the blockchain, we may process IP address, public keys, blockchain addresses and user credentials that are not directly tied to any public key or blockchain address.  However, the App does not store user credentials.  Nor does the App store, user settings, account information or email addresses.  All user settings are stored locally on the user’s device; the settings page does not communicate with dcSpark’s servers.</p>
                <p className="mb-20">We operate social media pages on third party networks and have social media icons in the App. When you visit or link to our social media pages, data is processed both by us and the applicable social media provider. Social media providers are unaffiliated with us, and we are not responsible for the content or privacy practices of social media providers.  Social media providers have their own terms of use and privacy policies, and we encourage you to review those policies whenever you visit their websites or interact with their platforms.</p>
                <p className="mb-20">We do not knowingly collect information from users under the age of 18 and such persons are not authorized to use the App. By installing and using the App, you represent that you are at least 18 years old and of legal age to enter into legal agreements.</p>
            
                <h2 className="font-bold text-28 mt-20 mb-30">2.	How We Use the Data</h2>
                <p className="mb-20">We use the personal data we collect to respond to server queries, provide and enhance the App and its functionality, perform our contractual and legal obligations, and for other legitimate business purposes. </p>

                <h2 className="font-bold text-28 mt-20 mb-30">3.	With Whom We Share Personal Data</h2>
                <p className="mb-20">dcSpark is headquartered in the United States, and as such may need to share information with internal personnel and our affiliates located in different geographic locations in order to administer the App.  We may also share personal data with third parties (within or outside your country of residence) who perform services on our behalf, including without limitation our technology providers.  </p>
                <p className="mb-20">We may disclose information (within or outside your country of residence) if we have a good faith belief that disclosure is necessary by law or the legal process, to protect and defend our or others’ interests or property, or to enforce agreements you enter into with us.  </p>
                <p className="mb-20">Your information may be transferred to another company (within or outside your country of residence) in connection with a merger or in the event that our business is acquired in whole or part by another company</p>
                <p className="mb-20">We may share aggregated and de-identified information with third parties (within or outside your country of residence) for analytical, research or other similar purposes. </p>
                <p className="mb-20">We may obtain your written consent from time to time in electronic form by using online agreements or other acknowledgements in the App, including for any other contemplated uses of your personal data not addressed in this Privacy Policy. Please read all online agreements carefully before accepting them.  </p>

                <h2 className="font-bold text-28 mt-20 mb-30">4.	Grounds for Using Your Personal Data</h2>
                <p className="mb-20">We rely on the following legal grounds to process your personal information:</p>
                <p className="mb-20"><u>Consent</u>. Some uses of your personal data as described in this Privacy Policy are subject to your consent, such as the use of Cookies.  You may also refrain from providing, or withdraw, your consent for Cookies via your browser and mobile settings.</p>
                <p className="mb-20"><u>Performance of a contract</u>. We may need to collect and use your personal information to enter into a contract with you and to perform services that you request. </p>
                <p className="mb-20"><u>Legitimate Interests</u>. We may use your personal information for our legitimate interests to provide and improve our App.</p>
                <p className="mb-20"><u>Compliance with Legal Obligations</u>. We may use your personal information as necessary to comply with our legal obligations.</p>

                <h2 className="font-bold text-28 mt-20 mb-30">5.	Storage and Protection of Your Personal Data</h2>
                <p className="mb-20">We retain the personal data we collect for so long as reasonably necessary to fulfill the purposes for which the data was collected and to perform our contractual and legal obligations. Logs are kept temporarily, with the retention period determined by the particular log tool. </p>
                <p className="mb-20">We take reasonable administrative, physical and technical precautions to protect your personal data.  This includes, when required or as we deem appropriate and feasible under the circumstances, encryption and written commitments from third parties that may have access to your data that they will protect the data with safeguards that are substantially equivalent to those used by dcSpark.  </p>
                <p className="mb-20">No electronic transmission is ever fully secure or error free, however.  We therefore cannot guarantee absolute security of your data, and we are not responsible for processes and networks that we do not control.  Users assume the risk of security breaches and the consequences resulting from them.    </p>

                <h2 className="font-bold text-28 mt-20 mb-30">6.	Your Rights, Choices and Disclosures</h2>
                <ol type="a">
                    <li className="list-alpha">
                        <p className="mb-20">
                            <b>General.</b>
                            This section is subject to specific disclosures on privacy rights set forth below. We are committed to facilitate the exercise of your rights granted by the laws of your jurisdiction, which may include the right to request the correction, modification or deletion of your personal information and the right to opt out of the sale of your personal information (as applicable). We will do our best to honor your requests subject to any legal and contractual obligations.  If you would like to make a request, contact us at privacy@dcspark.io or make changes to any user settings in the App (such as opt-out preferences for analytics).
                        </p>
                        <p className="mb-20">
                            Subject to local law, you may have additional rights under the laws of your jurisdiction regarding your personal data, such as the right to complain to your local data protection authority. 
                        </p>
                    </li>
                    <li className="list-alpha">
                        <div className="mb-20">
                            <b>Your European Privacy Rights and Choices. </b>
                            If you reside or otherwise find yourself in the territory of Europe, your privacy rights under the European data protection law include:
                            <ul className="list-disc">
                                <li className="mt-10"><u>Transparency and the right to information.</u> Through this policy we explain how we use and share your information. However, if you have questions or need additional information you can contact us any time.</li>
                                <li className="mt-10"><u>Right of access, restriction of processing, erasure. </u> You may contact us to request information about the personal data we have collected from you and to request the correction, modification or deletion of such personal information, which requests we will do our best to honor subject to any legal and contractual obligations.  To make a request or to ask us a question about our data practices, please contact us via email at privacy@dcspark.io.  </li>
                                <li className="mt-10"><u>Right to withdraw your consent at any time. </u> When we process your personal data based on your consent, you have the right to withdraw it at any time.</li>
                                <li className="mt-10"><u>Right to object at any time. </u> You have the right to object at any time to any processing of your personal data based on your specific situation. We will assess your request and provide a reply in a timely manner, according to our legal and contractual obligations.  Some non-marketing communications are not subject to a general opt-out, such as communications about transactions and disclosures to comply with legal requirements.</li>
                                <li className="mt-10"><u>Right to data portability. </u> You have the right to data portability of your own personal data by contacting us.</li>
                                <li className="mt-10"><u>Right not to be subject to an automated decision, including profiling. </u> We do not make automated decisions using your personal data that may negatively impact you.</li>
                                <li className="mt-10"><u>Right to lodge a complaint with a supervisory authority. </u> If you consider that the processing of your personal data infringes your privacy rights according the European Privacy regulation, you have the right to lodge a complaint with a supervisory authority, in the member state of your habitual residence, place of work, or place of the alleged infringement.</li>
                            </ul>
                        </div>
                    </li>
                    <li className="list-alpha">
                        <p className="mb-20">
                            <b>California Disclosures</b>
                        </p>
                        <p className="mb-20">
                            <u>California “Shine the Light” Information-Sharing Disclosure</u>: California residents may request a list of all third parties with respect to which we have disclosed any information about you for direct marketing purposes and the categories of information disclosed.  If you are a California resident and want such a list, please send us a written request by email to privacy@dcspark.io with “California Shine The Light Rights” in the subject line.  
                        </p>
                        <p className="mb-20">
                            <u>California Do Not Track Disclosure</u>: The App runs as a browser extension, not a website, so we do not respond to Do Not Track browser settings as they are inapplicable.
                        </p>
                        <p className="mb-20">
                            <u>California Consumer Privacy Act (“CCPA”) Disclosures.</u> Under the California Consumer Privacy Act (CCPA), California residents have the right to request:
                            <ul className="list-disc mb-20">
                                <li>The categories of personal information we have collected about you;</li>
                                <li>The categories of sources from which the personal information is collected;</li>
                                <li>The business or commercial purpose of collecting or selling personal information;</li>
                                <li>The categories of third parties with whom we share or sell personal information;</li>
                                <li>The categories of personal information about you that we have sold; and</li>
                                <li>The specific pieces of personal information we have collected about you.</li>
                            </ul>
                            Additionally, you have the right to request deletion of your personal information, the right to opt out of the sale of your information (if applicable) and the right not to be discriminated against for exercising any of your CCPA rights. 
                        </p>
                        <p className="mb-20">
                            Please see the chart below regarding the categories of data that we have collected or received in connection with the App in the 12 months preceding the effective date of this Privacy Policy and to whom we may have disclosed the information.
                        </p>
                        <table className="table-auto mb-20 border">
                            <thead>
                              <tr className="border">
                                <th className="border"><b>Personal Information Category</b></th>
                                <th className="border"><b>Source of Personal Information</b></th>
                                <th className="border"><b>Purpose for Collecting the Personal Information</b></th>
                                <th className="border"><b>To whom we disclose the Personal Information</b></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border">
                                <td className="border">Identifiers such as a name, address, unique personal identifier, email, phone number</td>
                                <td className="border">Information you provide to us; Information we collect automatically</td>
                                <td className="border">See Section 2 above</td>
                                <td className="border">See Section 3 above</td>
                              </tr>
                              <tr className="border">
                                <td className="border">Protected Classifications under California and federal law, including gender, age and citizenship.</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Commercial information such as records of products or services purchased, obtained, or considered</td>
                                <td className="border">Information you provide to us; Information we collect automatically</td>
                                <td className="border">See Section 2 above</td>
                                <td className="border">See Section 3 above</td>
                              </tr>
                              <tr className="border">
                                <td className="border">Biometric information</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Internet or other electronic network activity information, including browsing history and search history</td>
                                <td className="border">Information you provide to us; Information we collect automatically</td>
                                <td className="border">See Section 2 above</td>
                                <td className="border">See Section 3 above</td>
                              </tr>
                              <tr className="border">
                                <td className="border">Geolocation data that is sufficient to identify a precise physical location</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Sensory data, such as audio, electronic, visual, or other similar information.</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Professional; Professional or employment-related information</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Education Information</td>
                                <td className="border">We do not collect this information</td>
                                <td className="border"></td>
                                <td className="border"></td>
                              </tr>
                              <tr className="border">
                                <td className="border">Inferences about preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes</td>
                                <td className="border">Information you provide to us; Information we collect automatically</td>
                                <td className="border">See Section 2 above</td>
                                <td className="border">See Section 3 above</td>
                              </tr>
                            </tbody>
                          </table>
                          <p className="mb-20"><span className="text-red">We do not sell personal information for monetary consideration under CCPA</span>. Personal data may be provided to our service providers and to third parties under Section 1798.140(t)(2)(A) of the CCPA. In the event that the use of Google Analytics cookies constitutes a “sale” under CCPA, you may opt out of the use of such cookies by downloading the browser add-on here: <a href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.</p>
                          <p className="mb-20">Our contact information is listed at the bottom of this policy. If you submit a data subject request, we will ask for your name and email address. If your name and email address are insufficient to verify your identity and assess your privacy request, we may need to ask for additional information.  You may also designate an authorized agent to make a CCPA privacy request.</p>
                    </li>
                </ol>

                <h2 className="font-bold text-28 mt-20 mb-30">7.	Users Outside the United States</h2>
                <p className="mb-20">The App is controlled and operated from the United States. If you are an individual from the European Union, Canada or any other jurisdiction with laws or regulations governing personal data collection, use, and disclosure that differ from United States laws, please be advised that we may store the information we collect in the United States which is not deemed an “adequate jurisdiction” by the European regulatory authorities.  Personal data may also be transferred from the country of your residence to other countries, including the United States.  </p>

                <h2 className="font-bold text-28 mt-20 mb-30">8.	Links to Other Sites</h2>
                <p className="mb-20">The App may contain links to other websites for your convenience or information (including social media pages).  These websites are operated by companies unaffiliated with us, and we are not responsible for the content or privacy practices of those websites.  Linked websites may have their own terms of use and privacy policies, and we encourage you to review those policies whenever you visit the websites. </p>

                <h2 className="font-bold text-28 mt-20 mb-30">9.	Privacy Policy Changes</h2>
                <p className="mb-20">We may update this Privacy Policy from time to time and without prior notice to you to reflect changes in our information practices, and any such amendments shall apply to information already collected and to be collected. Your continued use of the App after any changes to our Privacy Policy indicates your agreement with the terms of the revised Privacy Policy.  Please review this Privacy Policy periodically and especially before you provide personal data to us.  If we make material changes to this Privacy Policy, we will notify you here or by means of an in-app notice.  The date of the last update of the Privacy Policy is indicated at the top of this Privacy Policy.  </p>

                <h2 className="font-bold text-28 mt-20 mb-30">10.	Questions and Contact Information</h2>
                <p className="mb-20">
                    Please contact us if you have any questions about our Privacy Policy. You may contact us by sending correspondence to the foregoing address or by emailing us at privacy@dcspark.io. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
